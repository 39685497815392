<template>
  <div class="reset-password-container d-flex flex-column justify-content-center align-items-center">
    <form @submit.prevent="resetPassword" class="reset-password-form">
      <div class="form-group-register">
        <label for="new_password" class="form-label">Nouveau mot de passe</label>
        <input
            type="password"
            id="new_password"
            class="form-control"
            v-model="newPassword"
            placeholder="Nouveau mot de passe"
            required
        />
      </div>
      <div class="form-group-register">
        <label for="confirm_password" class="form-label">Confirmez le mot de passe</label>
        <input
            type="password"
            id="confirm_password"
            class="form-control"
            v-model="confirmPassword"
            placeholder="Confirmez le mot de passe"
            required
        />
        <span v-if="passwordMismatch" class="text-danger">Les mots de passe ne correspondent pas</span>
      </div>
      <div class="mt-auto w-100 text-center">
        <button
            type="submit"
            class="btn btn-primary"
            :disabled="isLoading || passwordMismatch"
        >
          <span v-if="isLoading" class="spinner"></span>
          <span v-else>Réinitialiser le mot de passe</span>
        </button>
      </div>
    </form>

    <div v-if="statusMessage" :class="statusClass" class="status-message mt-3">
      {{ statusMessage }}
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosConfig";
import { store } from "@/store";


export default {
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      statusMessage: "",
      statusClass: "",
      isLoading: false, // Indicateur de chargement
    };
  },
  computed: {
    token() {
      console.log("Route Query :", this.$route.query);
      return this.$route.query.token; // Accéder au token via la route
    },
    passwordMismatch() {
      return this.newPassword && this.confirmPassword && this.newPassword !== this.confirmPassword;
    }
  },
  mounted() {
    store.pucksMessage = "Repartons sur de bonnes bases"
  },
  methods: {
    async resetPassword() {
      if (this.passwordMismatch) {
        this.statusMessage = "Les mots de passe ne correspondent pas";
        this.statusClass = "alert alert-danger";
        return;
      }

      this.isLoading = true;
      try {
        const formData = new URLSearchParams();
        formData.append("new_password", this.newPassword);
        formData.append("token", this.token);
        console.log("token :", this.token)

        const response = await axiosInstance.post(`/api/reset-password`, formData, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });


        this.statusMessage = response.data.message;
        this.statusClass = "alert alert-success"

        setTimeout(() => {
          this.$router.push({ path: "/" }); // Redirige vers la page d'accueil
        }, 2000);
      } catch (error) {
        this.statusMessage =
            error.response?.data?.detail || "Erreur lors de la réinitialisation.";
        this.statusClass = "alert alert-danger";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.reset-password-container {
  background-color: rgba(0, 0, 0, 0.8); /* Fond sombre semi-transparent */
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: auto;
  margin-top: 50px;
}

.reset-password-form .form-group-register {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #cccccc;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #444;
  border-radius: 5px;
  height: 2.5rem;
  padding-left: 10px;
  color: #333;
}

.text-danger {
  color: #dc3545;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert-success {
  background-color: rgba(40, 167, 69, 0.7);
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7);
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
