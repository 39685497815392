//FileUploadGen.vue
<template>
  <div class="upload-container">
    <div v-if="isLoading" class="loader"></div>
    <!-- Afficher le spinner lors du chargement -->

    <div v-else>
      <!-- Masquer le formulaire et le bouton lors du chargement -->
      <div class="form-group">
        <label for="fileInput">Fichier:</label>
        <input
          type="file"
          id="fileInput"
          class="form-control-file"
          @change="handleFileChange"
        />
      </div>

      <button class="btn btn-success" @click="submitFile">Upload</button>
    </div>
  </div>
</template>
  
  <script>
import axiosInstance from "../axiosConfig";
import { store } from "@/store";

export default {
  setup() {
    return { store };
  },
  props: {
    route: String,
    fileName: String
  },
  data() {
    return {
      selectedFile: null,
      isLoading: false, // Ajoutez cette propriété pour gérer l'affichage du spinner
    };
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    validatePrice() {
      this.priceError = !this.pricePerkg;
    },
  wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
},
    async workingPuck() {
      while(
        this.isLoading
      )
      {
        await store.generatePucksLoading()
        }
      store.pucksMessage = "🦾 Trop facile."
    },
    async submitFile() {
      if (!this.selectedFile) {
        alert("Veuillez sélectionner un fichier");
        return;
      }
      this.priceError = false;
      this.isLoading = true;
      this.workingPuck();
      store.pucksMessage = "Du travail, encore du travail"// Début du chargement

      let formData = new FormData();
      formData.append("file", this.selectedFile);

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Aucun jeton d'authentification trouvé");
        this.isLoading = false; // Arrêt du chargement en cas d'erreur
        return;
      }

      try {
        const url = `/api${this.route}`;
        const response = await axiosInstance.post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        });
        if (this.fileName !== '') {
          // Créer l'URL du blob et le lien de téléchargement
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          console.log('filename: ' + this.fileName)
          fileLink.setAttribute("download", this.fileName);
          document.body.appendChild(fileLink);

          // Facultatif : déclencher le téléchargement automatiquement
          fileLink.click();

          // Supprimer le lien du DOM
          document.body.removeChild(fileLink);
          fileLink.remove();
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi du fichier :", error);
        this.loginError =
          error.response.data.detail || "Une erreur est survenue";
      } finally {
        this.isLoading = false;
        this.$emit('upload-complete'); // Arrêt du chargement après la requête
      }
    },
  },
};
</script>
  
  <style scoped>
</style>
  