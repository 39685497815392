//router.js
import { createRouter, createWebHistory } from 'vue-router';
import { store } from './store';
import FileUpload from './components/FileUpload.vue';
import LoginMod from './components/Login_mod.vue';
import Admin from './components/Admin_mod.vue';
import Democracy from './components/Democracy_mod.vue';
import MultiTools from './components/MultiTools.vue';
import RoleBasedModule from './components/RoleBasedModule.vue';
import GpxAnalyzer from './components/GpxAnalyzer.vue';
import UserRegister from './components/UserRegister.vue'; // Composant pour la création de compte
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import AccountActivation from './components/AccountActivation.vue'; // Composant pour l'activation de compte

const routes = [
  { path: '/', component: LoginMod },
  {
    path: '/register',
    component: UserRegister,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/activate',
    component: AccountActivation,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/home',
    component: RoleBasedModule,
    meta: { requiresAuth: true },
  },
  {
    path: '/home/file-upload',
    component: FileUpload,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'gold_digger'] },
  },
  {
    path: '/home/admin',
    component: Admin,
    meta: { requiresAuth: true, allowedRoles: ['admin'] },
  },
  {
    path: '/home/democracy',
    component: Democracy,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'meps'] },
  },
  {
    path: '/home/multitools',
    component: MultiTools,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'tools', 'bundle'] },
  },
  {
    path: '/home/gpx-analyzer',
    component: GpxAnalyzer,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'gpx', 'bundle'] },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Vérification d'authentification
    if (!store.isLoggedIn || !store.userInfo.token) {
      next({ path: '/', query: { redirect: to.fullPath } }); // Redirige vers login
    } else {
      const allowedRoles = to.meta.allowedRoles;
      const userRole = store.userInfo?.userRole || localStorage.getItem("role");

      if (allowedRoles && !allowedRoles.includes(userRole)) {
        store.errorMessage = {
          visible: true,
          message: "Vous n'êtes pas autorisé à accéder à cette page.",
        };
        next('/'); // Redirige si le rôle n'est pas autorisé
      } else {
        next(); // Autorise l'accès
      }
    }
  } else {
    next();
 }
});



export default router;
