// RoleBasedModule.vue
<template>
  <div>
    <!-- Affiche les icônes uniquement si l'utilisateur est sur la route principale -->
    <div v-if="isOnMainRoute" class="role-based-module">
      <!-- Icône pour les rôles 'admin' et 'gold_digger' -->
      <router-link
          v-if="isRole('admin') || isRole('gold_digger')"
          to="/home/file-upload"
          class="image-button-container"
      >
        <div class="image-button" :style="{ backgroundImage: 'url(' + goldDiggerImage + ')' }">
          <span class="button-text">Diggin' it !</span>
        </div>
        <div class="sub-text">Traitement des fichiers de ventes d'or</div>
      </router-link>

      <!-- Icône pour le rôle 'admin' -->
      <router-link
          v-if="isRole('admin')"
          to="/home/admin"
          class="image-button-container"
      >
        <div class="image-button" :style="{ backgroundImage: 'url(' + adminImage + ')' }">
          <span class="button-text">Admin</span>
        </div>
        <div class="sub-text">Interface d'administration</div>
      </router-link>

      <!-- Icône pour les rôles 'admin' et 'meps' -->
      <router-link
          v-if="isRole('admin') || isRole('meps')"
          to="/home/democracy"
          class="image-button-container"
      >
        <div class="image-button" :style="{ backgroundImage: 'url(' + democracyImage + ')' }">
          <span class="button-text">Démocratie</span>
        </div>
        <div class="sub-text">Données d'intérêt démocratique</div>
      </router-link>

      <!-- Icône pour les rôles 'admin' et 'tools' -->
      <router-link
          v-if="isRole('admin') || isRole('tools') || isRole('bundle')"
          to="/home/multitools"
          class="image-button-container"
      >
        <div class="image-button" :style="{ backgroundImage: 'url(' + multiTools + ')' }">
          <span class="button-text">Vrac</span>
        </div>
        <div class="sub-text">Outils en vrac</div>
      </router-link>

      <!-- Icône pour les rôles 'admin' et 'gpx' -->
      <router-link
          v-if="isRole('admin') || isRole('gpx') || isRole('bundle')"
          to="/home/gpx-analyzer"
          class="image-button-container"
      >
        <div class="image-button" :style="{ backgroundImage: 'url(' + navStats + ')' }">
          <span class="button-text">GPX Analyzer</span>
        </div>
        <div class="sub-text">Analyses de trajets GPX</div>
      </router-link>
    </div>

    <!-- Rendu des composants selon la route -->
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { store } from "@/store";

export default {
  name: "RoleBasedModule",
  computed: {
    userRole() {
      return store.userInfo.userRole;
    },
    // Vérifie si on est exactement sur la route principale
    isOnMainRoute() {
      return this.$route.path === "/home";
    }
  },
  data() {
    return {
      goldDiggerImage: require("../../images/gold_digger.webp"),
      adminImage: require("../../images/admin.webp"),
      democracyImage: require("../../images/democracy.webp"),
      multiTools: require("../../images/multitools.webp"),
      navStats: require("../../images/carto_stat.webp"),
    };
  },
  methods: {
    isRole(role) {
      return this.userRole === role;
    },
    validateToken: async function () {
      try {
        const response = await fetch(`/api/validate_token`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${store.userInfo.token}`,
          },
        });

        if (!response.ok) {
          store.isLoggedIn = false;
          store.userInfo.token = '';
          store.userInfo.userName = '';
          store.userInfo.userRole = '';
          store.userInfo.userEmail = '';
          localStorage.removeItem("token");
          this.$emit('logout');
          throw new Error('Token invalide');
        }

        const data = await response.json();
        console.log("Token valide:", data);

      } catch (error) {
        console.error("Erreur lors de la validation du token :", error);
        store.isLoggedIn = false;
        store.userInfo.token = '';
        store.userInfo.userName = '';
        store.userInfo.userRole = '';
        store.userInfo.userEmail = '';
        localStorage.removeItem("token");
        this.$emit('logout');
      }
    },
  },
  mounted() {
    console.log("Mounted RoleBasedModule. UserRole:", store.userInfo.userRole);
    store.pucksMessage = "Choisis ton poison, " + store.userInfo.userName;
    this.validateToken();
  },
};
</script>

<style scoped>
/* Ajoutez ici vos styles pour la mise en page des icônes */
</style>
