<template>
  <div class="register-container d-flex flex-column justify-content-center align-items-center">
    <form @submit.prevent="registerUser" class="register-form">
      <div class="form-group-register">
        <label for="user_name" class="form-label">Nom d'utilisateur</label>
        <input
            type="text"
            id="user_name"
            class="form-control"
            v-model="user_name"
            placeholder="Nom d'utilisateur"
            required
        />
      </div>
      <div class="form-group-register">
        <label for="full_name" class="form-label">Nom complet</label>
        <input
            type="text"
            id="full_name"
            class="form-control"
            v-model="full_name"
            placeholder="Nom complet"
        />
      </div>
      <div class="form-group-register">
        <label for="email" class="form-label">Email</label>
        <input
            type="email"
            id="email"
            class="form-control"
            v-model="email"
            placeholder="Email"
            required
        />
      </div>
      <div class="form-group-register">
        <label for="password" class="form-label">Mot de passe</label>
        <input
            type="password"
            id="password"
            class="form-control"
            v-model="password"
            @input="evaluatePassword"
            placeholder="Mot de passe"
            required
        />
        <span class="password-strength" :class="getStrengthClass(passwordScore)">
          {{ passwordStrength }}
        </span>
      </div>
      <div class="form-group-register">
        <label for="confirmPassword" class="form-label">Confirmez le mot de passe</label>
        <input
            type="password"
            id="confirmPassword"
            class="form-control"
            v-model="confirmPassword"
            placeholder="Confirmez le mot de passe"
            required
        />
        <span v-if="passwordMismatch" class="text-danger">Les mots de passe ne correspondent pas</span>
      </div>
      <div class="mt-auto w-100 text-center">
        <button
            type="submit"
            class="btn btn-primary"
            :disabled="isLoading || passwordMismatch || passwordScore < 2"
        >
          <span v-if="isLoading" class="spinner"></span>
          <span v-else>Créer un compte</span>
        </button>
      </div>
    </form>

    <!-- Affichage du message de statut -->
    <div v-if="statusMessage" :class="statusClass">{{ statusMessage }}</div>
  </div>
</template>

<script>
import zxcvbn from "zxcvbn";
import axiosInstance from "../axiosConfig";
import { store } from "../store";

export default {
  name: "UserRegister",
  data() {
    return {
      user_name: "",
      full_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      statusMessage: "",
      statusClass: "",
      isLoading: false, // Indicateur de chargement pour l'animation
      passwordStrength: "",
      passwordScore: 0
    };
  },
  computed: {
    passwordMismatch() {
      return this.password && this.confirmPassword && this.password !== this.confirmPassword;
    }
  },
  methods: {
    evaluatePassword() {
      const result = zxcvbn(this.password);
      this.passwordScore = result.score; // Score de 0 à 4
      this.passwordStrength = this.getStrengthLabel(result.score);
    },
    getStrengthLabel(score) {
      switch (score) {
        case 0:
          return "Très faible";
        case 1:
          return "Faible";
        case 2:
          return "Moyen";
        case 3:
          return "Fort";
        case 4:
          return "Très fort";
        default:
          return "Inconnu";
      }
    },
    getStrengthClass(score) {
      switch (score) {
        case 0:
        case 1:
          return "text-danger";
        case 2:
          return "text-warning";
        case 3:
        case 4:
          return "text-success";
        default:
          return "";
      }
    },
    async registerUser() {
      if (this.passwordMismatch) {
        this.statusMessage = "Les mots de passe ne correspondent pas";
        this.statusClass = "alert alert-danger";
        return;
      }

      try {
        this.isLoading = true;
        const formData = new URLSearchParams();
        formData.append("user_name", this.user_name);
        formData.append("full_name", this.full_name);
        formData.append("email", this.email);
        formData.append("password", this.password);

        const response = await axiosInstance.post(`/api/register`, formData, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        });

        this.statusMessage = response.data.message;
        this.statusClass = "alert alert-success";
        // Redirection vers la page d'accueil après un délai
        setTimeout(() => {
          this.$router.push({ path: "/" }); // Redirige vers la page d'accueil
        }, 2000);
      } catch (error) {
        this.statusMessage =
            error.response?.data?.detail || "Erreur lors de la création du compte";
        this.statusClass = "alert alert-danger";
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    store.pucksMessage = "🚨 🚔 👮‍♀️ 🛑 Papiers, s'il vous plait 🚨"
  }
};
</script>

<style scoped>
.register-container {
  background-color: rgba(0, 0, 0, 0.8); /* Fond sombre semi-transparent */
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: auto;
  margin-top: 50px;
}

.register-form .form-group-register {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #cccccc;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #444;
  border-radius: 5px;
  height: 2.5rem;
  padding-left: 10px;
  color: #333;
}

.password-strength {
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 5px;
  display: block;
}

.text-danger {
  color: #dc3545;
}

.text-warning {
  color: #ffc107;
}

.text-success {
  color: #28a745;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #0056b3;
}

.alert-success {
  background-color: rgba(40, 167, 69, 0.7);
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7);
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
